import React, { useEffect, useRef, useState } from "react"
import ResizeObserver from "resize-observer-polyfill"
import styled from "styled-components"

const buttonSize = 12

const CarouselButton = styled.button`
  background-color: ${props => (props.active ? "white" : "transparent")};
  border: 1px solid white;
  border-radius: 50%;
  height: ${buttonSize}px;
  margin: 0 ${buttonSize / 2}px;
  padding: 0;
  outline: none;
  width: ${buttonSize}px;

  :hover {
    cursor: pointer;
  }
`

const CarouselButtonContainer = styled.div`
  bottom: 0%;
  display: flex;
  height: auto;
  justify-content: center;
  left: 0;
  margin: 0 auto 12px;
  right: 0;
  position: absolute;
  width: auto;
`

const CarouselItem = styled.div`
  height: 100%;
  position: absolute;
  width: 100%;
  transform: translateX(${props => props.index * 100}%);
`

const CarouselItems = styled.div`
  height: 100%;

  :hover {
    cursor: grab;
  }

  :active {
    cursor: grabbing;
  }
`

const CarouselWrapper = styled.div`
  height: ${props => props.height}px;
  position: relative;
  overflow: hidden;
  width: 100%;
`

const DEFAULT_DURATION_MS = 8000

const Carousel = ({
  children,
  controls,
  duration = DEFAULT_DURATION_MS,
  durations,
  itemHeight,
}) => {
  const numCarouselItems = React.Children.count(children)

  const INITIAL_INDEX = 0
  const [currentIndex, setCurrentIndex] = useState(INITIAL_INDEX)

  // Get initial width
  const wrapperEl = useRef(null)
  const [width, setWidth] = useState(0)

  useEffect(() => {
    setWidth(wrapperEl.current.getBoundingClientRect().width)
  }, [])

  // Update if width changes
  useEffect(() => {
    if (typeof ResizeObserver !== "undefined") {
      const wrapperResizeObserver = new ResizeObserver(entries => {
        for (const entry of entries) {
          setWidth(entry.contentRect.width)
        }
      })
      wrapperResizeObserver.observe(wrapperEl.current)
      return () => wrapperResizeObserver.unobserve(wrapperEl.current)
    }
  })

  // Auto-scroll
  let interval = null
  const buttonRefs = []

  const goToNextIndex = () => {
    setCurrentIndex((currentIndex + 1) % numCarouselItems)
  }

  // Change timings for different slides
  useEffect(() => {
    const ms = (durations && durations[currentIndex]) || duration
    interval = setInterval(goToNextIndex, ms)
    return () => clearInterval(interval)
  })

  // Button click navigation
  const handleButtonClick = index => {
    if (index === currentIndex) return
    setCurrentIndex(index)
    clearInterval(interval)
  }

  // Drag states
  const [isHeld, setIsHeld] = useState(false)
  const [holdStartX, setHoldStartX] = useState(0)
  const [moveAmountPercent, setMoveAmountPercent] = useState(0)

  // Distinguish between mouse and touch
  const handleMouseStart = evt => {
    evt.preventDefault() // prevent highlighting text
    handleDragStart(evt.pageX)
  }
  const handleMouseMove = evt => handleDragMove(evt.pageX)

  const handleTouchStart = evt => handleDragStart(evt.touches.item(0).pageX)
  const handleTouchMove = evt => handleDragMove(evt.touches.item(0).pageX)

  // Drag carousel item
  const handleDragStart = dragStartX => {
    clearInterval(interval)
    setIsHeld(true)
    setHoldStartX(dragStartX)
  }

  // Handle movement while dragging
  const handleDragMove = dragMoveX => {
    if (!isHeld) return

    clearInterval(interval)
    const moveDistance = dragMoveX - holdStartX

    // Limit move amount before first and after last slide
    const beforeFirst = currentIndex === 0 && moveDistance > 0
    const afterLast = currentIndex === numCarouselItems - 1 && moveDistance < 0

    if (beforeFirst || afterLast) {
      setMoveAmountPercent((moveDistance / 10 / width) * 100)
    } else {
      setMoveAmountPercent((moveDistance / width) * 100)
    }
  }

  const MOVE_THRESHOLD_PERCENT = 20

  // Handle snap after releasing
  const handleDragEnd = () => {
    if (!isHeld) return

    // Prevent movement before first and after last slide
    const beforeFirst = currentIndex === 0 && moveAmountPercent > 0
    const afterLast =
      currentIndex === numCarouselItems - 1 && moveAmountPercent < 0

    if (!beforeFirst && !afterLast) {
      if (moveAmountPercent > MOVE_THRESHOLD_PERCENT) {
        setCurrentIndex(currentIndex - 1)
      } else if (moveAmountPercent < -MOVE_THRESHOLD_PERCENT) {
        setCurrentIndex(currentIndex + 1)
      }
    }

    setIsHeld(false)
    setMoveAmountPercent(0)
    setHoldStartX(0)
  }

  // Attach and clear event listeners on re-render
  useEffect(() => {
    window.addEventListener("mousemove", handleMouseMove)
    window.addEventListener("mouseup", handleDragEnd)
    document.addEventListener("touchmove", handleTouchMove)
    document.addEventListener("touchend", handleDragEnd)
    return () => {
      window.removeEventListener("mousemove", handleMouseMove)
      window.removeEventListener("mouseup", handleDragEnd)
      document.removeEventListener("touchmove", handleTouchMove)
      document.removeEventListener("touchend", handleDragEnd)
    }
  })

  return (
    <CarouselWrapper height={itemHeight} ref={wrapperEl}>
      <CarouselItems
        onMouseDown={handleMouseStart}
        onTouchStart={handleTouchStart}
        style={{
          transform: `translateX(${currentIndex * -100 + moveAmountPercent}%)`,
          transition: `${isHeld ? "transform 0s" : "transform 1s"}`,
        }}
      >
        {React.Children.map(children, (child, index) => (
          <CarouselItem index={index}>{child}</CarouselItem>
        ))}
      </CarouselItems>
      {controls && (
        <CarouselButtonContainer>
          {React.Children.map(children, (value, index) => (
            <CarouselButton
              active={index === currentIndex}
              key={index}
              onClick={() => handleButtonClick(index)}
              ref={button => (buttonRefs[index] = button)}
            />
          ))}
        </CarouselButtonContainer>
      )}
    </CarouselWrapper>
  )
}

export default Carousel
