import { Link } from "gatsby"
import React from "react"
import styled from "styled-components"

import arrowIcon from "$assets/arrow-icon.svg"
import Banner from "$components/Banner"
import Carousel from "$components/Carousel"
import Container from "$components/Container"
import Footer from "$components/Footer"
import Header from "$components/Header"
import SEO from "$components/SEO"

import bannerCovid from "./assets/banner-covid.jpg"
import bannerHomeAccounting from "./assets/banner-home-accounting.jpg"
import bannerHomeLegal from "./assets/banner-home-legal.jpg"
import bannerHomeMain from "./assets/banner-home-main.jpg"
import iconAustralia from "./assets/icon-australia-color.svg"
import iconBackup from "./assets/icon-backup-white.svg"
import iconBuilding from "./assets/icon-building-color.svg"
import iconHandshake from "./assets/icon-handshake-color.svg"
import iconMonitor from "./assets/icon-monitor-white.svg"
import iconPhone from "./assets/icon-phone-white.svg"
import iconServer from "./assets/icon-server-white.svg"
import iconWifi from "./assets/icon-wifi-white.svg"
import iconWorld from "./assets/icon-world-white.svg"
import partnerLeap from "./assets/partner-leap.png"
import partnerMYOB from "./assets/partner-myob.png"
import partnerReckonAPS from "./assets/partner-reckon-aps.jpg"
import partnerSageHandisoft from "./assets/partner-sage-handisoft.jpg"

const BannerContainer = styled(Container)`
  align-items: ${props => (props.right ? "flex-end" : "flex-start")};
  color: white;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: flex-end;
`

const BannerHighlight = styled.span`
  color: ${props => props.theme.rouge};
`

// Each spacer is one unit of space
const BannerSpacer = styled.div`
  flex: 1;
`

const BannerText = styled.div`
  color: white;
  font-family: ${props => props.theme.fontSecondary};
  font-size: 1.2rem;
  margin-bottom: 1rem;
  text-align: ${props => props.textAlign || "left"};
  width: 50%;

  @media only screen and ${props => props.theme.deviceSizes.mobile} {
    width: 100%;
  }
`

const CentredP = styled.p`
  text-align: center;
`

// const Columns = styled.div`
//   display: flex;
//   margin: 0 auto;
//   width: 60%;
// `

const ContactButton = styled.button.attrs(() => ({ as: Link }))`
  background-color: transparent;
  border: solid 1px white;
  color: inherit;
  font-family: inherit;
  font-size: inherit;
  margin-bottom: 8px;
  outline: none;
  padding: 12px 32px;
  text-decoration: none;
  text-transform: uppercase;
  transition: 0.3s;
  width: max-content;

  :hover {
    background-color: white;
    color: ${props => props.theme.tundora};
  }
`

const ContactText = styled.p`
  font-size: 1.2rem;
  margin: 0 0 1rem 0;
  text-align: right;
  width: auto;
`

const ContactButtonContainer = styled.div`
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin: 1rem 0 2rem;
`

// const CopyrightLink = styled.a`
//   color: inherit;
// `

// const CovidContainer = styled.div`
//   background-color: ${props => props.theme.gallery};
//   padding: 1rem 0;
// `

// const CovidH2 = styled.h2`
//   color: ${props => props.theme.rouge};
// `

const CovidBannerText = styled(BannerText)`
  flex: 0;
`

const CovidUl = styled.ul`
  margin: 0;
  width: max-content;

  li {
    font-family: ${props => props.theme.fontSecondary};
    font-size: 1.2rem;
    margin: 4px;
  }
`

const H1 = styled.h1`
  box-sizing: border-box;
  font-size: 3rem;
  font-weight: 700;
  margin: 2rem 0;
  text-align: ${props => props.textAlign || "left"};

  @media only screen and ${props => props.theme.deviceSizes.mobile} {
    font-size: 2.5rem;
  }
`

// Defined after H1
const CovidH1 = styled(H1)`
  flex: 0;
  margin: 1rem 0;
`

const FeatureListItem = styled(Container)`
  display: flex;

  @media only screen and ${props => props.theme.deviceSizes.mobile} {
    display: block;
    margin-bottom: 2rem;
  }
`

const FeatureListImage = styled.img`
  align-self: center;
  height: 80px;
  margin-right: 40px;

  @media only screen and ${props => props.theme.deviceSizes.mobile} {
    display: block;
    margin: 0 auto;
  }
`

const FeatureListText = styled.div`
  display: flex;
  flex-direction: column;
  max-width: max-content;

  h3 {
    margin: 1rem 0;
    text-align: left;
    width: min-content;
  }

  p {
    margin: 0;
    width: 100%;
  }

  @media only screen and ${props => props.theme.deviceSizes.mobile} {
    display: block;

    h3,
    p {
      margin: 1rem auto;
      text-align: center;
    }
  }
`

const Icon = styled.img`
  height: 40px;
`

const IconBackground = styled.div`
  align-items: center;
  background-attachment: fixed;
  background-image: linear-gradient(
    to right,
    ${props => props.theme.rouge},
    ${props => props.theme.goldenDream}
  );
  border-radius: 50%;
  display: flex;
  height: 80px;
  justify-content: center;
  width: 80px;
`

const IconGrid = styled.div`
  display: grid;
  flex: 2;
  grid-template-columns: repeat(3, minmax(200px, 1fr));

  @media only screen and ${props => props.theme.deviceSizes.mobile} {
    grid-gap: 16px;
    grid-template-columns: repeat(2, 1fr);
  }
`

const IconGridContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  margin: 4rem auto;
  max-width: 960px;
  width: 100%;

  @media only screen and (max-width: 720px) {
    flex-direction: column;
  }
`

const IconLinkAll = styled(Link)`
  align-items: center;
  display: flex;
  flex: 1;
  height: 100px;
  flex-direction: column;
  margin: 1rem 0;
  overflow: visible;
  text-decoration: none;

  @media only screen and (max-width: 720px) {
    width: 100%;
  }
`

const IconLinkAllArrow = styled.img`
  height: 50px;
  transform: rotate(-90deg);
`

const IconLinkAllText = styled.p`
  color: ${props => props.theme.nero};
  text-align: center;

  ${IconLinkAll}:hover & {
    text-decoration: underline;
  }
`

const IconWrapperLink = styled(Link)`
  align-items: center;
  display: flex;
  flex-direction: column;
  text-decoration: none;
`

// Defined after IconWrapperLink
const IconText = styled.p`
  color: ${props => props.theme.nero};
  font-size: 1.2rem;
  margin-top: 1rem;
  text-align: center;

  ${IconWrapperLink}:hover & {
    text-decoration: underline;
  }
`

const LogoContainer = styled.div`
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`

const PartnerLogo = styled.img`
  height: 80px;
  margin: 0 40px;

  @media only screen and ${props => props.theme.deviceSizes.mobile} {
    margin: 0;
  }
`

const Strong = styled(H1).attrs(() => ({ as: "strong" }))``

const bannerHeight = 650

const title = "Accounting and Legal IT Specialists | Lotus Network Solutions"

export default () => (
  <>
    <SEO title={title} />
    <Header />
    <main>
      <Carousel controls durations={[14000]} itemHeight={bannerHeight}>
        <Banner bannerImage={bannerCovid} height={bannerHeight}>
          <BannerContainer right>
            <CovidH1 as={"strong"} textAlign="right">
              Managed IT services for the new normal
            </CovidH1>
            <CovidBannerText textAlign="right">
              Our new circumstances bring new technology needs. LOTUS can manage
              your IT to keep you and your team resilient and productive. Our
              expertise covers:
            </CovidBannerText>
            <CovidUl>
              <li>Video conferencing equipment</li>
              <li>Work From Home environments</li>
              <li>Remote technical support</li>
            </CovidUl>
            <ContactButtonContainer>
              <ContactText>
                Get in touch for solutions that meet your needs:
              </ContactText>
              <ContactButton to="/contact/">Contact Us</ContactButton>
            </ContactButtonContainer>
          </BannerContainer>
        </Banner>
        <Banner bannerImage={bannerHomeMain} height={bannerHeight}>
          <BannerContainer right>
            <BannerSpacer />
            <BannerSpacer />
            <H1 textAlign="right">
              When it comes to accounting
              <br />
              and legal, let <BannerHighlight>LOTUS handle IT.</BannerHighlight>
            </H1>
            <BannerText textAlign="right">
              At LOTUS, we specialise in managed IT services for accounting and
              legal practices. We work to provide the most suitable solutions
              for you with unparalleled service.
            </BannerText>
            <ContactButtonContainer>
              <ContactButton to="/contact/">Contact Us</ContactButton>
            </ContactButtonContainer>
          </BannerContainer>
        </Banner>
        <Banner bannerImage={bannerHomeAccounting} height={bannerHeight}>
          <BannerContainer>
            <BannerSpacer />
            <BannerSpacer />
            <BannerSpacer />
            <Strong>LOTUS for accounting</Strong>
            <BannerText>
              LOTUS specialises in accounting software suites such as MYOB, Sage
              Handisoft and ReckonAPS to keep your business running.
            </BannerText>
            <ContactButtonContainer>
              <ContactButton to="/contact/">Contact Us</ContactButton>
            </ContactButtonContainer>
          </BannerContainer>
        </Banner>
        <Banner bannerImage={bannerHomeLegal} height={bannerHeight}>
          <BannerContainer right>
            <BannerSpacer />
            <BannerSpacer />
            <BannerSpacer />
            <Strong textAlign="right">LOTUS for legal</Strong>
            <BannerText textAlign="right">
              LOTUS is your partner for all of your technology needs, including
              the Leap software suite for legal practice management.
            </BannerText>
            <ContactButtonContainer>
              <ContactButton to="/contact/">Contact Us</ContactButton>
            </ContactButtonContainer>
          </BannerContainer>
        </Banner>
      </Carousel>
      <section>
        <LogoContainer>
          <a
            href="https://www.leap.com.au/"
            rel="noopener noreferrer"
            target="_blank"
          >
            <PartnerLogo alt="Leap logo" src={partnerLeap} />
          </a>
          <a
            href="https://www.myob.com/au"
            rel="noopener noreferrer"
            target="_blank"
          >
            <PartnerLogo alt="MYOB logo" src={partnerMYOB} />
          </a>
          <a
            href="https://www.reckon.com/au/aps/"
            rel="noopener noreferrer"
            target="_blank"
          >
            <PartnerLogo alt="Reckon APS logo" src={partnerReckonAPS} />
          </a>
          <a
            href="https://www.sage.com/en-au/products/handisoft/"
            rel="noopener noreferrer"
            target="_blank"
          >
            <PartnerLogo alt="Sage Handisoft logo" src={partnerSageHandisoft} />
          </a>
        </LogoContainer>
      </section>
      <section>
        <Container>
          <h2>Partnering with you</h2>
          <CentredP>
            Lotus Network Solutions partners with accounting and legal firms
            throughout Australia to provide stable, personalised IT solutions
            using industry best practices.
          </CentredP>
          <CentredP>
            Each of our clients receives a dedicated Technology Adviser who will
            handle any and all of your IT needs. Your Technology Adviser is
            responsible for the efficient, stable and responsive functioning of
            your managed environment, supported by the expertise of LOTUS's
            in-house and contracted staff and our distributor network, service
            providers and manufacturers.
          </CentredP>
          <CentredP>
            All of our staff are based locally in Australia. When you need
            support, don't call a helpdesk. With LOTUS, your dedicated
            Technology Adviser is available for all of your technology needs.
          </CentredP>
        </Container>
      </section>
      <section>
        <h2>Managed IT Services</h2>
        <CentredP>
          At our core, IT service and support is what we do best.
        </CentredP>
        <IconGridContainer>
          <IconGrid>
            <IconWrapperLink to="/services/#server-systems-lan-support">
              <IconBackground>
                <Icon alt={"Server icon"} src={iconServer} />
              </IconBackground>
              <IconText>
                Server
                <br />
                Systems
              </IconText>
            </IconWrapperLink>
            <IconWrapperLink to="/services/#application-user-desktop-support">
              <IconBackground>
                <Icon alt={"Monitor icon"} src={iconMonitor} />
              </IconBackground>
              <IconText>
                App and
                <br />
                User Desktop
              </IconText>
            </IconWrapperLink>
            <IconWrapperLink to="/services/#router-wireless-internet-provider">
              <IconBackground>
                <Icon alt={"World icon"} src={iconWifi} />
              </IconBackground>
              <IconText>
                Router and
                <br />
                Wireless
              </IconText>
            </IconWrapperLink>
            <IconWrapperLink to="/services/#backup-systems">
              <IconBackground>
                <Icon alt={"Backup icon"} src={iconBackup} />
              </IconBackground>
              <IconText>
                Backup
                <br />
                Systems
              </IconText>
            </IconWrapperLink>
            <IconWrapperLink to="/services/#domain-website-services">
              <IconBackground>
                <Icon alt={"World icon"} src={iconWorld} />
              </IconBackground>
              <IconText>
                Domain and
                <br />
                Website
              </IconText>
            </IconWrapperLink>
            <IconWrapperLink to="/services/#voice-mobile-device-management">
              <IconBackground>
                <Icon src={iconPhone} />
              </IconBackground>
              <IconText>
                Voice and
                <br />
                Mobile Device
              </IconText>
            </IconWrapperLink>
          </IconGrid>
          <IconLinkAll to="/services/">
            <IconLinkAllArrow src={arrowIcon} />
            <IconLinkAllText>See all services</IconLinkAllText>
          </IconLinkAll>
        </IconGridContainer>
      </section>
      <section>
        <h2>Why choose LOTUS?</h2>
        <FeatureListItem>
          <FeatureListImage alt={"Handshake icon"} src={iconHandshake} />
          <FeatureListText>
            <h3>Trust</h3>
            <p>
              We manage information technology for accounting and legal firms
              across Australia.
            </p>
          </FeatureListText>
        </FeatureListItem>
        <FeatureListItem>
          <FeatureListImage alt={"Australia icon"} src={iconAustralia} />
          <FeatureListText>
            <h3>Reach</h3>
            <p>
              We work with rural and regional practices to provide the same
              level of technology and support as our city clients.
            </p>
          </FeatureListText>
        </FeatureListItem>
        <FeatureListItem>
          <FeatureListImage alt={"Building icon"} src={iconBuilding} />
          <FeatureListText>
            <h3>Scale</h3>
            <p>
              We have the ability to provide distributed support throughout
              Australia and quickly realise projects.
            </p>
          </FeatureListText>
        </FeatureListItem>
      </section>
    </main>
    <Footer />
  </>
)

// icon acknowledgement

// Icons made by <a href="https://www.flaticon.com/authors/dinosoftlabs" title="DinosoftLabs">DinosoftLabs</a> from <a href="https://www.flaticon.com/" title="Flaticon"> www.flaticon.com</a>
